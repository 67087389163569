//  Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from 'context/header';
import { FooterContext } from 'context/footer';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import InformationPage from 'components/contactUs/informationPage';
import Menu from 'components/contactUs/menu';

// Images
import blobPurple from 'images/blobs/Slime_Purple.webp';
import blobPink from 'images/blobs/Slime_Pink.webp';

// Styles
const Container = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  padding-top: 1.875rem;

  @media ${device.laptop} {
    display: block;
    padding: 0 20px;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media ${device.laptop} {
    flex-direction: column;
    padding: 0 0;
  }
`;

const BoxBlobs = styled.figure`
  display: none;
  width: calc(100% + 4rem);
  height: 2rem;
  margin-left: -40px;

  @media ${device.laptop} {
    bottom: 0;
    margin: 0;
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    transform: translate(2%, 16%);
    position: relative;
  }
`;

const BlobPurple = styled.img`
  z-index: 3;
  width: 22.5625rem;
  height: 19.625rem;
  transform: translate(12%, -7%);
`;

const BlobPink = styled.img`
  z-index: 2;
  width: 17.1875rem;
  height: 13.375rem;
  transform: translate(-4%, -14%);
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const ContactUs = ({ page }) => {
  const { setCallCenterBackLink } = useContext(HeaderContext);

  const { setContactUs } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setCallCenterBackLink('fale-conosco');
      setContactUs('fale-conosco');
    }
  }, []);

  return (
    <Layout>
      <SEO dataSeo={page} />
      <Container>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'cki4zh5vk0vzq0a256lqnarkx', true)}
          />
          <Content>
            <InformationPage
              markdown={getMarkdown(page, 'cki4zodwo0wpg0a292p0dn48w', true)}
              fragments={getMarkdown(page, 'cki4zodwo0wpg0a292p0dn48w')}
              isFaleConoco
            />
            <Menu />
          </Content>
        </Wrapper>
      </Container>
      <BoxBlobs>
        <BlobPurple src={blobPurple} alt="blob lilás" />
        <BlobPink src={blobPink} alt="blob rosa" />
      </BoxBlobs>
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cki7i9cyw01tj09203zyq0zw0" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <ContactUs page={response.gcms.site.pages} />;
      }}
    />
  );
};
