import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

// Images
import blobPurple from 'images/blobs/Slime_Purple.webp';
import blobPink from 'images/blobs/Slime_Pink.webp';

// Styles
const Container = styled.div`
  width: auto;
  height: auto;
  overflow: hidden;

  @media ${device.laptop} {
    padding-top: 1.9375rem;
  }
  @media ${device.mobile} {
    padding-top: 0.5rem;
  }

  > h1 {
    width: 29.375rem;
    font-size: 3.375rem;
    font-weight: 700;
    color: #231f20;

    @media ${device.laptop} {
      width: 21rem;
      font-size: 1.5rem;
    }

    @media ${device.mobile320} {
      width: ${props => props.home && 'auto'};
      font-size: 1.4rem;
      word-break: break-word;
    }
  }

  > p {
    width: 23.125rem;
    font-size: 1rem;
    font-weight: 400;
    color: #8f9194;
    padding-top: 1.875rem;
    line-height: 26px;

    @media ${device.laptop} {
      width: ${props => props.home && 'auto'};
      padding-top: 1.25rem;
    }
  }
`;

const BoxBlobs = styled.figure`
  display: flex;
  transform: translate(-5%, 75%);

  @media ${device.laptop} {
    display: none;
  }
`;

const BlobPurple = styled.img`
  width: 22.5625rem;
  height: 19.625rem;
  z-index: 3;
`;

const BlobPink = styled.img`
  width: 17.1875rem;
  height: 13.375rem;
  transform: translateX(-30%);
  z-index: 2;
`;

const InformationPage = ({ markdown, fragments, isFaleConoco }) => {
  const { singletexts } = fragments && fragments[0];

  return (
    <Container home>
      <ReactMarkdown children={markdown} allowDangerousHtml />
      {isFaleConoco ? null : <h1>{singletexts[0]}</h1>}
      <BoxBlobs>
        <BlobPurple src={blobPurple} alt="Blob Lilás" />
        <BlobPink src={blobPink} alt="Blob Rosa" />
      </BoxBlobs>
    </Container>
  );
};

export default InformationPage;
